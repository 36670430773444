import { http } from "../libs/http";
import APIHandler, { apiUrls } from "../constants/apiUrl";
import { processRequest } from "./asyncActionProcessor";
import ResponseHandler from "../libs/response";
import {
  requestBuilder,
} from "../libs/utilities";
import { ADMIN_USER, BROKER_USER, LAW_FIRM_USER, LENDER_USER } from "../constants/systemUsers";

export const getMatchingLoans = ({
  id,
  profileType,
  userType,
  cancelToken,
  queryParams,
  generalView = false
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const url = APIHandler.constructEndpoint({
        endpoint: generalView ? apiUrls.PUBLIC_MATCHING_LOANS_FOR_BROKER_URL :  profileType == "lender"
          ? (userType == ADMIN_USER ? apiUrls.GET_MATCHING_LOANS_FOR_LENDER_URL_AS_SYSTEM_USER : apiUrls.GET_MATCHING_LOANS_FOR_LENDER_URL)
          : userType == ADMIN_USER ? apiUrls.GET_MATCHING_LOANS_FOR_BROKER_URL_AS_SYSTEM_USER : 
            apiUrls.GET_MATCHING_LOANS_FOR_BROKER_URL,
        options: { id }
      });

      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_MATCHING_LOANS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};


export const getPresettledLoans = ({
  id,
  profileType,
  userType,
  cancelToken,
  queryParams
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      let actionUrl;

      switch(profileType){
        
        case LENDER_USER:
          actionUrl = userType == ADMIN_USER ? apiUrls.GET_PRESETTLED_LOANS_FOR_LENDER_AS_ADMIN_URL 
            : apiUrls.GET_PRESETTLED_LOANS_FOR_LENDER_URL;
          break;
        case LAW_FIRM_USER:
          actionUrl = userType == ADMIN_USER ? apiUrls.GET_PRESETTLED_LOANS_FOR_LAW_FIRM_URL_AS_ADMIN_URL
           : apiUrls.GET_PRESETTLED_LOANS_FOR_LAW_FIRM_URL;
          break;
        default:
          actionUrl = userType == ADMIN_USER ? apiUrls.GET_PRESETTLED_LOANS_FOR_BROKER_AS_ADMIN_URL 
            : apiUrls.GET_PRESETTLED_LOANS_FOR_BROKER_URL;
          break;

      }

      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,
        options: { id }
      });

      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_PRESETTLED_LOANS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getLoanDetails = ({
  id,
  userType,
  isUserInvolvedAsLender,
  isUserInvolvedAsBroker,
  cancelToken,
  generalView = false
}) => {
  return dispatch =>
    new Promise(async resolve => {
      let actionUrl;

      if(generalView){
        actionUrl = apiUrls.PUBLIC_LOANS_DETAILS_URL; 
      }
      else if (userType === "SYSUSER" || userType === ADMIN_USER) { // prevent mismatch from used ADMIN_USER on pages/Loans/Create
        actionUrl = apiUrls.GET_LOAN_DETAILS_FOR_SYSTEM_USER;
      } else if (
        userType !== "SYSUSER" &&
        (isUserInvolvedAsLender || isUserInvolvedAsBroker)
      ) {
        if (isUserInvolvedAsBroker) {
          actionUrl = apiUrls.GET_LOAN_DETAILS_FOR_BROKER_USER;
        } else {
          actionUrl = apiUrls.GET_LOAN_DETAILS_FOR_LENDER_USER;
        }
      } else if (
        userType !== "SYSUSER" &&
        (!isUserInvolvedAsLender && !isUserInvolvedAsBroker)
      ) {
        actionUrl = apiUrls.GET_LOAN_DETAILS_FOR_UNINVOLVED_USER;
      }

      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,
        options: { id }
      });

      try {
        const response = await http.get(url, cancelToken);
        resolve(processRequest(dispatch, response, "GET_LOAN_DETAILS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const checkUserLoanInvolvement = ({ id, userId, cancelToken }) => {
  return dispatch =>
    new Promise(async resolve => {
      let actionUrl = apiUrls.CHECK_USER_INVOLVEMENT;

      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,
        options: { id }
      });

      try {
        const response = await http.get(url);
        resolve(processRequest(dispatch, response, "CHECK_USER_INVOLVEMENT"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const updateLoanInView = ({ id, payload, userType, cancelToken }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("id is Required");
      }

      let actionUrl;

      if (userType === LENDER_USER) {
        actionUrl = apiUrls.UPDATE_LOAN_IN_VIEW_AS_LENDER;
      } else if (userType === BROKER_USER) {
        actionUrl = apiUrls.UPDATE_LOAN_IN_VIEW_AS_BROKER;
      } else if (userType === ADMIN_USER) {
        actionUrl = apiUrls.UPDATE_LOAN_IN_VIEW_AS_SYSTEM_USER;
      } else if (userType === LAW_FIRM_USER){
        actionUrl = apiUrls.UPDATE_LOAN_IN_VIEW_AS_LAWFIRM
      }
      
      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,
        options: { loanid: id }
      });

      try {
        const response = await http.put(url, payload, cancelToken);
        resolve(processRequest(dispatch, response, "UPDATE_LOAN_IN_VIEW"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const deleteLoan = ({ id, payload, cancelToken }) => {
  return dispatch =>
    new Promise(async resolve => {
      if (!id) {
        throw new Error("id is Required");
      }
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.DELETE_LOAN_AS_SYSTEM_USER,
        options: { id }
      });
      try {
        const response = await http.put(
          `${url}?id=${payload && payload.id}`,
          payload,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "DELETE_LOANS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getAttributedLenders = ({ id, userType }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint: userType == ADMIN_USER ? 
          apiUrls.GET_LOAN_ATTRIBUTED_LENDER_FOR_SYSTEM_USER : apiUrls.GET_LOAN_ATTRIBUTED_LENDER,
        options: { id }
      });

      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LOAN_ATTRIBUTED_LENDERS")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getAttributedBrokers = ({ id, userType, generalView = false }) => {
  return dispatch =>
    new Promise(async resolve => {
      const url = APIHandler.constructEndpoint({
        endpoint: generalView ? apiUrls.PUBLIC_LOANS_DETAILS_ATTRIBUTED_BROKERS_URL : userType == ADMIN_USER ? 
          apiUrls.GET_LOAN_ATTRIBUTED_BROKERS_FOR_SYSTEM_USER : apiUrls.GET_LOAN_ATTRIBUTED_BROKERS,
        options: { id }
      });

      try {
        const response = await http.get(url);
        resolve(
          processRequest(dispatch, response, "GET_LOAN_ATTRIBUTED_BROKERS")
        );
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getCurrentLoans = ({
  id,
  profileType,
  userType,
  cancelToken,
  queryParams,
  generalView = false
}) => {
  return dispatch =>
    new Promise(async resolve => {
      let actionUrl;
      if(generalView){
        actionUrl = apiUrls.PUBLIC_BROKER_DETAILS_CURRENT_LOANS_URL;
      }
      else {
        switch (profileType) {
          case LENDER_USER:
            actionUrl =
              userType == ADMIN_USER
                ? apiUrls.GET_CURRENT_LOANS_FOR_LENDER_URL_AS_SYSTEM_USER
                : apiUrls.GET_CURRENT_LOANS_FOR_LENDER_URL;
  
            break;
  
          case LAW_FIRM_USER:
            actionUrl =
              userType == ADMIN_USER
                ? apiUrls.GET_CURRENT_LOANS_FOR_LAW_FIRM_URL_AS_SYSTEM_USER
                : apiUrls.GET_CURRENT_LOANS_FOR_LAW_FIRM_URL;
  
            break;
  
          default:
            actionUrl =
              userType == ADMIN_USER
                ? apiUrls.GET_CURRENT_LOANS_FOR_BROKER_URL_AS_SYSTEM_USER
                : apiUrls.GET_CURRENT_LOANS_FOR_BROKER_URL;
  
            break;
        }
      }

      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,

        options: { id }
      });

      try {
        const response = await requestBuilder(
          null,

          url,

          queryParams,

          cancelToken
        );

        resolve(processRequest(dispatch, response, "GET_CURRENT_LOANS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getDischargedLoans = ({
  id,
  profileType,
  userType,
  cancelToken,
  queryParams,
  generalView = false
}) => {
  return dispatch =>
    new Promise(async resolve => {
      let actionUrl;
      if(generalView){
        actionUrl = apiUrls.PUBLIC_BROKER_DETAILS_DISCHARGED_LOANS_URL;
      }
      else {
        switch (profileType) {
          case LENDER_USER:
            actionUrl =
              userType == ADMIN_USER
                ? apiUrls.GET_DISCHARGED_LOANS_FOR_LENDER_URL_AS_SYSTEM_USER
                : apiUrls.GET_DISCHARGED_LOANS_FOR_LENDER_URL;
  
            break;
  
          case LAW_FIRM_USER:
            actionUrl =
              userType == ADMIN_USER
                ? apiUrls.GET_DISCHARGED_LOANS_FOR_LAW_FIRM_URL_AS_SYSTEM_USER
                : apiUrls.GET_DISCHARGED_LOANS_FOR_LAW_FIRM_URL;
  
            break;
  
          default:
            actionUrl =
              userType == ADMIN_USER
                ? apiUrls.GET_DISCHARGED_LOANS_FOR_BROKER_URL_AS_SYSTEM_USER
                : apiUrls.GET_DISCHARGED_LOANS_FOR_BROKER_URL;
  
            break;
        }
      }
      

      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,
        options: { id }
      });
      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        console.log(response);
        resolve(processRequest(dispatch, response, "GET_DISCHARGED_LOANS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getDoneWithMeLoans = ({
  id,
  profileType,
  cancelToken,
  queryParams,
}) => {
  return dispatch =>
    new Promise(async resolve => {
      let actionUrl;
      switch (profileType) {
        case LENDER_USER:
          actionUrl = apiUrls.LENDERS_LOANS_DONE_WITH_YOU;
          break;
        case BROKER_USER:
          actionUrl = apiUrls.BROKER_LOANS_DONE_WITH_YOU;
          break;
        default:
          resolve(processRequest(dispatch, {data: []}))
      }
      const url = APIHandler.constructEndpoint({
        endpoint: actionUrl,
        options: { id }
      });

      try {
        const response = await requestBuilder(
          null,
          url,
          queryParams,
          cancelToken
        );
        resolve(processRequest(dispatch, response, "GET_DONE_WITH_ME_LOANS"));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const loansCreate = ({ cancelToken, payload }) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls.LOANS_CREATE,
      options: {}
    });
    const response = await http.post(url, payload, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return { status: true, data: response.data };
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

export const loansEdit = ({ cancelToken, payload }) => {
  return async () => {
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls.LOANS_EDIT,
      options: { id: payload.id }
    });
    const response = await http.put(url, payload, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return { status: true, data: response.data };
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

export const getAllLoansAsSystemUser = ({
  queryParams={}
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.GET_ALL_LOANS_AS_SYSTEM_USER,
        options: {}
      });

      try {
        const response = await requestBuilder(null, url,  queryParams);
        return(resolve(processRequest(dispatch, response, "GET_ALL_LOANS_AS_SYSTEM_USER")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getAllLoansAsIndividual = ({
  queryParams={}
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.GET_ALL_LOANS_AS_INDIVIDUAL,
        options: {}
      });

      try {
        const response = await requestBuilder(null, url,  queryParams);
        return(resolve(processRequest(dispatch, response, "GET_ALL_LOANS_AS_INDIVIDUAL")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getAllLoansExpiryLookups = ({
  cancelToken
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const url = APIHandler.constructEndpoint({
        endpoint: apiUrls.GET_ALL_LOANS_EXPIRY_LOOKUPS,
        options: {}
      });

      try {
        const response = await requestBuilder(null, url, {}, cancelToken);
        return(resolve(processRequest(dispatch, response, "GET_ALL_LOANS_EXPIRY_LOOKUPS")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getAllLoansBdmLookup = ({
  search=""
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.GET_ALL_LOANS_BDM_LOOKUP,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      try {
        const response = await http.get(url);
        return(resolve(processRequest(dispatch, response, "GET_ALL_LOANS_BDM_LOOKUP")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

export const getAllLoansPartnerLookups = ({
  search=""
}) => {
  return dispatch =>
    new Promise(async resolve => { 
      const apiUrl = APIHandler.constructEndpoint({
        endpoint: apiUrls.GET_ALL_LOANS_PARTNER_LOOKUPS,
        options: {}
      });
      const url = search ? `${apiUrl}?search=${search}` : apiUrl;
      try {
        const response = await http.get(url);
        return(resolve(processRequest(dispatch, response, "GET_ALL_LOANS_PARTNER_LOOKUPS")));
      } catch (err) {
        resolve(processRequest(dispatch, err));
      }
    });
};

// Loans Snapshot
/**
 * @typedef GetLoanLogsPayload
 * @property {number} loanId
 * @property {boolean=} isSystemUser
 * @property {any} cancelToken
 * 
 * @param {GetLoanLogsPayload} payload 
 * @returns {Promise<import("../libs/types").PagedCollection<import("../libs/types").ILoanLogsListItemDto[]>>}
 */
export const getLoanLogs = ({
  loanId,
  isSystemUser = false,
  cancelToken
}) => {
  return async () => {
    let actionUrl = apiUrls.LOANS_LOGS_URL;

    // Overwrite actionurl if system user
    if (isSystemUser) {
      actionUrl = apiUrls.LOANS_LOGS_AS_SYSTEM_USER_URL;
    }

    const url = APIHandler.constructEndpoint({
      endpoint: actionUrl,
      options: { 
        id: loanId
      }
    });

    const response = await http.get(url, cancelToken);
    if (ResponseHandler.isValidStatus(response)) {
      return response.data && response.data.result
        ? response.data.result
        : response.data;
    } else {
      return ResponseHandler.inValidObject(response);
    }
  };
};

/**
 * @typedef GetSnapshotDetailsProps
 * @property {number} loanId
 * @property {number} logId
 * @property {boolean=} isSystemUser
 * @property {any} cancelToken
 * 
 * @param {GetSnapshotDetailsProps} params 
 * @returns {Promise<import("../libs/types").ValidResponse<import("../libs/types").ILoanSnapshotDetailsDto>>} 
 */
export const getLoanSnapshotDetails = ({
  loanId,
  logId,
  isSystemUser = false,
  cancelToken
}) => {
  return dispatch => new Promise(async resolve => {
    let actionUrl = apiUrls.LOANS_LOGS_SNAPSHOT_URL;

    // Overwrite actionurl if system user
    if (isSystemUser) {
      actionUrl = apiUrls.LOANS_LOGS_SNAPSHOT_AS_SYSTEM_USER_URL;
    }

    const url = APIHandler.constructEndpoint({
      endpoint: actionUrl,
      options: { 
        loanId,
        logId
      }
    });

    try {
      const response = await http.get(url, cancelToken);
      resolve(
        processRequest(dispatch, response, "GET_LOAN_SNAPSHOT_DETAILS")
      );
    } catch (err) {
      resolve(processRequest(dispatch, err));
    }
  });
};

/**
 * 
 * @param {{
 *  loanNo: string,
 *  loanLenders: number[]
 * }} param0 
 */
export const validateLoanNo = async ({
  loanNo = "",
  loanLenders = [],
}) => {
  try {
    const queryParams = new URLSearchParams();
    queryParams.append("loanNo", loanNo);
    loanLenders.forEach( x => {
      queryParams.append("loanLenders", `${x}`);
    });
    const url = APIHandler.constructEndpoint({
      endpoint: apiUrls.LOANS_VERIFY_LOANNO + "?" + queryParams.toString()
    });
    const response = await http.get(url);
    return response;
  } catch (error) {
    return error;
  }
}