import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { loansStatusLookups } from "../../../actions/lookup";
import axios from "axios";
import { useDispatch } from "react-redux";
import { mustBeArray } from "../../../libs";

const StatusLookup = ({ value, handleFieldChange, validateOnBlur }) => {
  const [list, setList] = useState([]);
  const dispatchAction = useDispatch();
  const signal = axios.CancelToken.source();

  useEffect(() => {
    dispatchAction(loansStatusLookups({ cancelToken: signal.token })).then(
      resp => {
        setList(mustBeArray(resp));
      }
    );
  }, []);

  return (
    <div className="flex flex-align-center m-r-16 m-b-10">
      <b>Status</b>
      <Select
        className="w-260 m-l-6"
        value={value}
        onChange={value => {
          handleFieldChange({ field: "statusKey", value });
          validateOnBlur({ field: "statusKey", value });
        }}
        allowClear={false}
      >
        {list.map(data => {
          return (
            <Select.Option key={data.id} value={data.id}>
              {data.name}
            </Select.Option>
          );
        })}
      </Select>
      <span className="p-b-25 m-l-4" style={{ color: "red" }}>
        *{" "}
      </span>
    </div>
  );
};

export default StatusLookup;
