import React, { useState, useEffect, useMemo } from "react";
import { LoanForm } from "../../components/Loans/Index";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateHeader } from "../../actions/member";
import LoanCreateContext from "../../components/Loans/Create/LoanCreateContext";
import {
  loansLendersLookups,
  loansPartnerAssociateLookup,
  loansLawFirmUsersLookup,
  loansBrokersLookups
} from "../../actions/lookup";
import {
  formatDateTimeForPayload,
  isNullEmptyOrUndefined,
  mustBeArray
} from "../../libs";
import _, { xorBy } from "lodash";
import moment from "moment";
import { loanErrors, loanFieldErrors } from "../../constants/errors";
import {
  getAttributedLenders,
  getAttributedBrokers,
  getLoanDetails,
  loansCreate,
  loansEdit,
  validateLoanNo
} from "../../actions/loans";
import { message } from "antd";
import history from "../../routes/history";
import { ADMIN_USER } from "../../constants/systemUsers";
import authService from "../../actions/AuthorizeService";
import AddInactiveBrokerConfirmationModal from "../../components/Loans/Create/AddInactiveFinancierConfirmationModal";
import { ALL_LOANS_STATUS } from "../../constants";
import StatusErrorModal from "../../components/Loans/Create/StatusErrorModal";
import { isSystemUserPolicy } from "../../Common/Authorization/policies/isSystemUserPolicy";

const LoansCreate = ({ match }) => {

  const {user} = useSelector(state => state.authorization);
  
  const [loanDetails, setLoanDetails] = useState({
    loanNo: "",
    lawFirmId: null,
    lawFirm: null,
    lastEditBy: null,
    assignedAssociateId: null,
    brokerBdmId: null,
    lenderBdmId: null,
    assignedPartnerId: null,
    associatedBroker: null,
    lowerRate: null,
    higherRate: null,
    lvr: null,
    principal: null,
    priorityKey: null,
    establishmentFeeWithGst: null,
    repaymentDate: undefined,
    scenarioKey: null,
    securityTypeKey: null,
    securitySubTypeKey: null,
    settlementDate: undefined,
    dischargedDate: undefined,
    shortfall: null,
    statusKey: null,
    suburbState: null,
    lenderReviewByBroker: "",
    lenderStarsFromBroker: null,
    brokerResponseToLender: "",
    lawyerReviewByBroker: "",
    lawyerStarsFromBroker: null,
    lawyerResponseToBroker: "",
    brokerReviewByLender: "",
    brokerStarsFromLender: null,
    lenderResponseToBroker: "",
    lawyerReviewByLender: "",
    lawyerStarsFromLender: null,
    lawyerResponseToLender: "",
    loanLenders: [
      {
        lenderId: null,
        contributionAmount: null
      }
    ],
    interestKey: null,
    loanBrokers: [
      {
        brokerId: null
      }
    ],
    valuationRequired: null,
    credits: [],
  });
  const [priority, setPriority] = useState(null);
  const [loanId, setLoanId] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [securitySubTypesList, setSecuritySubTypesList] = useState([]);
  const [stateAndSuburbList, setStateAndSuburbList] = useState([]);
  const [partnerAssociateList, setPartnerAssociateList] = useState([]);
  const [lendersList, setLendersList] = useState([]);
  const [brokersList, setBrokersList] = useState([]);
  const [lendersAddModal, setLendersAddModal] = useState({
    saveLoading: false,
    visible: false
  });
  const [brokersAddModal, setBrokersAddModal] = useState({
    saveLoading: false,
    visible: false
  });
  const [statusErrorModal, setStatusErrorModal] = useState({
    visible: false,
    message: ""
  });
  

  const [errorsList, setErrorsList] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const signal = axios.CancelToken.source();
  const dispatchAction = useDispatch();

  const checkIfUserIsSystemUser = async () => {
    const isSystemUser = await isSystemUserPolicy(
      user.roles,
      user.permissions,
      user
    );
    return isSystemUser;
  };

  useEffect(() => {
    const id = match?.params?.id;
    dispatchAction(
      updateHeader({
        header: {
          title: " ",
          action: "",
          page: `loans-${id ? "edit" : "create"}-view`,
          enableBack: false,
          showSettings: true,
          showMasterSwitch: false,
          showIsProfileActive: false,
          hasSwitchProfile: false,
          editMode: false,
          showNotesButton: true,
          showOmicronBDM: false,
          omicronBdm: null,
          showPageTitle: false
        }
      })
    );
    if (id) {
      getLoanDetail(id);
      setLoanId(id);
    }
    getPartnerAssociateList();
    getLendersList();
    getBrokersList();

    const cleanUp = () => {
      signal.cancel("");
    };
    return cleanUp;
  }, []);

  // Calculate principal when loanlenders changes
  useEffect(() => {
    const principal = _.sumBy(
      loanDetails.loanLenders,
      o => o.contributionAmount
    );
    setLoanDetails({ ...loanDetails, principal });
  }, [loanDetails?.loanLenders]);

  const getPartnerAssociateList = () => {
    checkIfUserIsSystemUser().then(isSystemUser => {
      dispatchAction(
        loansPartnerAssociateLookup({ cancelToken: signal.token, isSystemAdmin: isSystemUser })
      ).then(resp => {
        setPartnerAssociateList(mustBeArray(resp));
      });
    });
  };


  const getLoanDetail = id => {
    dispatchAction(
      getLoanDetails({ cancelToken: signal.token, id, userType: "SYSUSER" })
    ).then(resp => {
      // Format data to include in loan details
      const {
        loanNo,
        associatedBroker,
        associatedBrokerId,
        brokerBdmId,
        lenderBdmId,
        lastEditBy,
        settlementDate,
        repaymentDate,
        dischargedDate,
        associateId,
        partnerId,
        establishmentFee,
        lawFirm,
        lawFirmId,
        lowerRate,
        higherRate,
        lvr,
        principal,
        priority,
        priorityKey,
        scenarioKey,
        securityTypeKey,
        securitySubTypeKey,
        shortfall,
        statusKey,
        suburbState,
        lenderReviewByBroker,
        lenderStarsFromBroker,
        brokerResponseToLender,
        lawyerReviewByBroker,
        lawyerStarsFromBroker,
        lawyerResponseToBroker,
        brokerReviewByLender,
        brokerStarsFromLender,
        lenderResponseToBroker,
        lawyerReviewByLender,
        lawyerStarsFromLender,
        lawyerResponseToLender,
        interestKey,
        id,
        valuationRequired,
        credits,
        constructionScenario,
        subdivisionScenario
      } = resp.data;

      dispatchAction(getAttributedLenders({ id, userType: ADMIN_USER })).then(res => {
        const loanLender = mustBeArray(res?.data).reduce((a, b) => {
          return [
            ...a,
            {
              lenderId: b.loanLenderId,
              contributionAmount: b.loanLenderContribution,
              omicronBdmId: b.omicronBdmId
              // ? Number(b.loanLenderContribution.replace("$", ""))
              // : null
            }
          ];
        }, []);
        dispatchAction(getAttributedBrokers({ id, userType: ADMIN_USER }))
          .then(res => {
            const loanBrokers = mustBeArray(res?.data).reduce((a, b) => {
              return [
                ...a,
                {
                  brokerId: b.loanBrokerId,
                  omicronBdmId: b.omicronBdmId
                }
              ];
            }, []);

            setLoanDetails({
              loanNo: !isNullEmptyOrUndefined(loanNo) ? loanNo : null,
              lawFirm: lawFirm,
              lawFirmId: lawFirmId,
              settlementDate: !isNullEmptyOrUndefined(settlementDate)
                ? moment(settlementDate)
                : undefined,
              repaymentDate: !isNullEmptyOrUndefined(repaymentDate)
                ? moment(repaymentDate)
                : undefined,
              dischargedDate: !isNullEmptyOrUndefined(dischargedDate)
                ? moment(dischargedDate)
                : undefined,
              assignedAssociateId: associateId,
              lastEditBy: lastEditBy,
              brokerBdmId: brokerBdmId,
              lenderBdmId: lenderBdmId,
              assignedPartnerId: partnerId,
              establishmentFeeWithGst: establishmentFee,
              loanLenders: _.isEmpty(loanLender)
                ? [{ lenderId: null, contributionAmount: null, omicronBdmId: null }]
                : loanLender,
              loanBrokers: _.isEmpty(loanBrokers)
                ? [{ brokerId: null, omicronBdmId: null }]
                : loanBrokers,
              lowerRate,
              higherRate,
              lvr,
              principal,
              priority,
              priorityKey,
              scenarioKey,
              securityTypeKey,
              securitySubTypeKey,
              shortfall,
              statusKey,
              suburbState,
              lenderReviewByBroker,
              lenderStarsFromBroker,
              brokerResponseToLender,
              lawyerReviewByBroker,
              lawyerStarsFromBroker,
              lawyerResponseToBroker,
              brokerReviewByLender,
              brokerStarsFromLender,
              lenderResponseToBroker,
              lawyerReviewByLender,
              lawyerStarsFromLender,
              lawyerResponseToLender,
              interestKey,
              id,
              credits: credits || [],
              valuationRequired: valuationRequired === true ? "true" : valuationRequired === false ? "false" : null, // Map to string because selection input only accepts string or number
              constructionScenario,
              subdivisionScenario
            });

          });
        
        
      });
    });
  };

  const getLendersList = () => {
    dispatchAction(loansLendersLookups({ cancelToken: signal.token })).then(
      resp => {
        setLendersList(mustBeArray(resp));
      }
    );
  };

  const getBrokersList = () => {
    dispatchAction(loansBrokersLookups({ cancelToken: signal.token })).then(
      resp => {
        setBrokersList(mustBeArray(resp));
      }
    );
  };

  const validateLoan = _.debounce((loanNo = "", loanLenders = []) => {
    validateLoanNo({
      loanNo,
      loanLenders: mustBeArray(loanLenders).map(x => x.lenderId)
    }).then(res => {
      if(res.data.errors){
        const errors = mustBeArray(res.data.errors).map(x => x.message);
        setValidationErrors(s => {
          let newErrorList = [...s];
          errors.forEach(q => {
            if(!newErrorList.includes(q)){
              newErrorList.push(q);
            }
          });
          return newErrorList;
        });
      }
    });
  }, 300);

  const setAttributedBroker = broker => {
    setLoanDetails({ ...loanDetails, attributedBrokerId: broker.id, brokerBdmId: broker.omicronBdmId });
  }

  const setLawFirm = lawFirm => {
    setLoanDetails({ ...loanDetails, lawFirmId: lawFirm.id});
  }

  const handleLoanLendersChange = data => {
    let sorted = data.sort((a, b) => b.contributionAmount - a.contributionAmount);
    const { loanNo } = loanDetails;

    setLoanDetails({ ...loanDetails, loanLenders: data, lenderBdmId: sorted[0].omicronBdmId });
    setValidationErrors([]);
    validateLoan(loanNo, data);
    handleLendersModalClose();
  }

  const handleLoanBrokersChange = data => {
    setLoanDetails({ ...loanDetails, loanBrokers: data, brokerBdmId: data[0].omicronBdmId });
    handleBrokersModalClose();
  }

  const handleFieldChange = data => {
    const { field, value, securitySubTypesList } = data;
    const {settlementDate, dischargedDate, loanLenders, loanNo} = loanDetails
    switch (field) {
      case "loanNo":
        setLoanDetails({ ...loanDetails, [field]: value });
        setValidationErrors([]);
        validateLoan(value, loanLenders);
        break;
      case "securityTypeKey":
        setSecuritySubTypesList(securitySubTypesList);
        setLoanDetails({
          ...loanDetails,
          [field]: value,
          securitySubTypeKey: ""
        });
        break;
      case "loanLenders":
        setLoanDetails({ ...loanDetails, [field]: value });
        handleLendersModalClose();
        break;
      case "loanBrokers":
        setLoanDetails({ ...loanDetails, [field]: value });
        handleBrokersModalClose();
        break;
      case "settlementDate":
        const dateIsAfterDischargedDate = !!(
          loanDetails.dischargedDate &&
          moment(value).isAfter(moment(loanDetails.dischargedDate))
        );
        setLoanDetails({
          ...loanDetails,
          [field]: value,
          dischargedDate: dateIsAfterDischargedDate
            ? null
            : loanDetails.dischargedDate,
          statusKey: loanDetails.statusKey === ALL_LOANS_STATUS.PreSettlement || !loanDetails.statusKey ? ALL_LOANS_STATUS.Settled : loanDetails.statusKey
        });
        validateOnBlur({field: 'statusKey', value: loanDetails.statusKey === ALL_LOANS_STATUS.PreSettlement ? ALL_LOANS_STATUS.Settled : loanDetails.statusKey});
        break;
        case "dischargedDate":
          setLoanDetails({
            ...loanDetails,
            [field]: value,
            statusKey: loanDetails.statusKey !== ALL_LOANS_STATUS.Discharged ? ALL_LOANS_STATUS.Discharged : loanDetails.statusKey
          });
          validateOnBlur({field: 'statusKey', value: loanDetails.statusKey !== ALL_LOANS_STATUS.Discharged ? ALL_LOANS_STATUS.Discharged : loanDetails.statusKey});
          break;
      case "priorityKey":
        setLoanDetails({
          ...loanDetails,
          [field]: value,
          priority: value === "FIRST" ? "First" : "Second"
        });
        break;
      case "statusKey":
        const hasSettlementDate = Boolean(settlementDate);
        const hasDischargedDate = Boolean(dischargedDate);
        if(hasDischargedDate){
          setLoanDetails({
            ...loanDetails,
            statusKey: value !== ALL_LOANS_STATUS.Discharged ? ALL_LOANS_STATUS.Discharged : value
          });
        }
        else if(hasSettlementDate && value === ALL_LOANS_STATUS.PreSettlement){
          setLoanDetails({
            ...loanDetails,
            statusKey: value === ALL_LOANS_STATUS.PreSettlement ? ALL_LOANS_STATUS.Settled : value
          });
        }
        else {
          setLoanDetails({ ...loanDetails, [field]: value });
        }
        break;
      default:
        setLoanDetails({ ...loanDetails, [field]: value });
        break;
    }
  };

  const handleSubmit = () => {};

  const stateAndSuburb = useMemo(() => {
    const stateAndSub = mustBeArray(loanDetails.suburbState?.split(", "));
    if (!_.isEmpty(stateAndSub)) {
      return {
        state: stateAndSub[1],
        suburb: stateAndSub[0]
      };
    }
    return {};
  }, [loanDetails.suburbState, stateAndSuburbList]);

  const handleLendersModalClose = () => {
    setLendersAddModal({ visible: false, saveLoading: false });
  };

  const displayLendersModal = () => {
    setLendersAddModal({ ...lendersAddModal, visible: true });
  };

  const handleBrokersModalClose = () => {
    setBrokersAddModal({ visible: false, saveLoading: false });
  };

  const displayBrokersModal = () => {
    setBrokersAddModal({ ...brokersAddModal, visible: true });
  };

  const validateFormFields = () => {
    let errors = [];
    const {
      loanNo,
      loanLenders,
      loanBrokers,
      principal,
      suburbState,
      lowerRate,
      higherRate,
      lvr,
      statusKey,
      settlementDate,
      repaymentDate,
      priorityKey,
      scenarioKey,
      securityTypeKey,
      securitySubTypeKey,
      interestKey,
      lawFirmId
    } = loanDetails;
    // Settlement date required when repayment date is filled, vice versa
    if(settlementDate && !repaymentDate) errors.push(loanErrors.repaymentDateRequired);
    if(repaymentDate && !settlementDate) errors.push(loanErrors.settlementDateRequired);

    // Loan No. is required
    if (!loanNo) errors.push(loanErrors.loanNumberRequired);

    // Attributed Omicron Lender is required
    const lenderError = isInValid({ field: "loanLenders", value: loanLenders });
    if (lenderError) errors.push(lenderError);

    const brokerError = isInValid({ field: "loanBrokers", value: loanBrokers });
    if (brokerError) errors.push(brokerError);

    // Principal is required
    if (!principal) errors.push(loanErrors.principalRequired);

    // suburb and State are required
    if (!suburbState) errors.push(loanErrors.suburbAndStateRequired);

    // validate lower rate
    const lowerRateError = isInValid({ field: "lowerRate", value: lowerRate });
    if (lowerRateError) errors.push(lowerRateError);

    // validate higher rate
    const higherRateError = isInValid({
      field: "higherRate",
      value: higherRate
    });
    if (higherRateError) errors.push(higherRateError);

    // validate lvr
    const lvrError = isInValid({ field: "lvr", value: lvr });
    if (lvrError) errors.push(lvrError);

    const statusError = isInValid({field: 'statusKey', value: statusKey});
    if(statusError) errors.push(statusError);

    if(!scenarioKey) errors.push(loanErrors.scenarioRequired);
    if(!priorityKey) errors.push(loanErrors.priorityRequired);
    if(!lawFirmId) errors.push(loanErrors.lawfirmRequired);
    if(!interestKey) errors.push(loanErrors.interestRequired);
    if(!securityTypeKey) errors.push(loanErrors.securityTypeRequired);
    if(!securitySubTypeKey) errors.push(loanErrors.securitySubTypeRequired);

    return errors;
  };
  const handleSave = () => {
    // Start validation
    const errors = validateFormFields();
    setErrorsList(errors);
    if (_.isEmpty([...errors, ...validationErrors])) {
      setSaveLoading(true);
      const suburbAndState = loanDetails.suburbState.split(", ");
      let payload = {
        ...loanDetails,
        suburb: suburbAndState[0],
        state: suburbAndState[1],
        settlementDate: formatDateTimeForPayload(loanDetails.settlementDate),
        dischargedDate: formatDateTimeForPayload(loanDetails.dischargedDate),
        repaymentDate: formatDateTimeForPayload(loanDetails.repaymentDate),
        loanBrokers: loanDetails.loanBrokers.map( x => {
          return x.brokerId
        }),
        statusKey: loanDetails.statusKey ? loanDetails.statusKey : ALL_LOANS_STATUS.PreSettlement,
        valuationRequired: loanDetails.valuationRequired === 'true' ? true : loanDetails.valuationRequired === 'false' ? false : null
      };
      delete payload.principal;
      delete payload.suburbState;
      const action = loanDetails?.id ? loansEdit : loansCreate;

      dispatchAction(action({ cancelToken: signal.token, payload })).then(
        resp => {
          setSaveLoading(false);

          if (!resp?.status) {
            const validationErrors = mustBeArray(resp?.data?.errors).reduce(
              (a, b) => {
                return [...a, b.message];
              },
              []
            );
            return setErrorsList(validationErrors);
          }
          message.success(
            `Loan ${payload.id ? "updated" : "created"} successfully.`
          );
          if (payload.id) {
            history.push(`/loans/${payload.id}/view`);
          } else {
            history.push(`/loans/${resp.data}/view`);
          }
        }
      );
    }
  };

  const handleCancel = () => {
    if (loanDetails.id == null) {
      history.push("/users/notes");
    } else {
      history.push(`/loans/${loanDetails.id}/view`);
    }
  };

  const isInValid = ({ field, value }) => {
    const { higherRate, lowerRate, settlementDate, dischargedDate } = loanDetails;
    switch (field) {
      case "loanLenders":
        const hasInValidLender = mustBeArray(value).some(
          o => !o.lenderId || !o.contributionAmount
        );
        return hasInValidLender
          ? loanErrors.attributedOmicronLenderRequired
          : "";
      case "loanBrokers":
        const hasInValidBroker = mustBeArray(value).some(
          o => !o.brokerId
        );
        return hasInValidBroker
          ? loanErrors.attributedOmicronBrokerRequired
          : "";
      case "suburbState":
        return value ? "" : loanErrors.suburbAndStateRequired;
      case "loanNo":
        return value ? "" : loanErrors.loanNumberRequired;
      case "principal":
        return value ? "" : loanErrors.principalRequired;
      case "lowerRate":
        const numeralValue = Number(value);
        if(isNullEmptyOrUndefined(value)) {
          return loanErrors.lowerRateRequired;
        }
        else if(
          numeralValue > 80 ||
          numeralValue < 2 ||
          numeralValue > higherRate )
        {
          return loanErrors.lowerRateValidation;
        }
        else {
          return "";
        }
      case "higherRate":
        const higherNumeralValue = Number(value);
        if(isNullEmptyOrUndefined(value)){
          return loanErrors.higherRateRequired;
        }
        else if( 
          higherNumeralValue > 80 ||
          higherNumeralValue < 2 ||
          higherNumeralValue < lowerRate)
        {
          return loanErrors.higherRateValidation;
        }
        else {
          return "";
        }
      case "lvr":
        if(value && (value > 99 || value < 5)){
          return loanErrors.lvrValidation
        }
        else {
          return "";
        }
      case 'statusKey':
        const hasSettlementDate = Boolean(settlementDate);
        const hasDischargedDate = Boolean(dischargedDate);
        if(hasDischargedDate && value !== ALL_LOANS_STATUS.Discharged){
          setStatusErrorModal({
            visible: true,
            message: loanErrors.statusKeyDischarged
          });
          // return loanErrors.statusKeyDischarged;
        }
        else if(hasSettlementDate && value === ALL_LOANS_STATUS.PreSettlement){
          setStatusErrorModal({
            visible: true,
            message: loanErrors.statusKeySettled
          });
          // return loanErrors.statusKeySettled;
        }
      default:
        return "";
    }
  };

  const validateOnBlur = ({ field, value }) => {
    const error = isInValid({ field, value });
    let tempErrorList = [...errorsList];
    switch (field) {
      case "lowerRate":
        const higherRateError = isInValid({
          field: "higherRate",
          value: loanDetails.higherRate
        });
        tempErrorList = getUpdatedErrorList({
          field: "higherRate",
          error: higherRateError,
          currentErrors: tempErrorList
        });
        tempErrorList = getUpdatedErrorList({
          field: "higherRateRequired",
          error: higherRateError,
          currentErrors: tempErrorList
        });
        tempErrorList = getUpdatedErrorList({
          field: "lowerRateRequired",
          error: error,
          currentErrors: tempErrorList
        });
        break;
      case "higherRate":
        const lowerRateError = isInValid({
          field: "lowerRate",
          value: loanDetails.lowerRate
        });
        tempErrorList = getUpdatedErrorList({
          field: "lowerRate",
          error: lowerRateError,
          currentErrors: tempErrorList
        });
        tempErrorList = getUpdatedErrorList({
          field: "lowerRateRequired",
          error: lowerRateError,
          currentErrors: tempErrorList
        });
        tempErrorList = getUpdatedErrorList({
          field: "higherRateRequired",
          error: error,
          currentErrors: tempErrorList
        });
        break;
      case 'statusKey':
        tempErrorList = getUpdatedErrorList({
          field: 'statusKeySettled',
          error: error,
          currentErrors: tempErrorList
        });
        tempErrorList = getUpdatedErrorList({
          field: 'statusKeyDischarged',
          error: error,
          currentErrors: tempErrorList
        });
        break;
      default:
        break;
    }
    const finalErrorList = getUpdatedErrorList({
      field,
      error,
      currentErrors: tempErrorList
    });
    setErrorsList(finalErrorList);
  };

  const getUpdatedErrorList = ({ currentErrors, error, field }) => {
    if (error && !currentErrors.includes(error))
      return [...currentErrors, error];
    if (!error) {
      const fieldError = loanFieldErrors[field];
      return currentErrors.filter(o => o !== fieldError);
    }
    return currentErrors;
  };

  const closeStatusErrorModal = () => {
    setStatusErrorModal({
      visible: false,
      message: ""
    });
  }

  return (
    <LoanCreateContext.Provider
      value={{
        securitySubTypesList,
        setStateAndSuburbList,
        partnerAssociateList,
        handleFieldChange,
        lendersAddModal,
        handleLendersModalClose,
        lendersList,
        handleLoanLendersChange,
        displayLendersModal,
        brokersAddModal,
        handleBrokersModalClose,
        brokersList,
        handleLoanBrokersChange,
        displayBrokersModal,
        validateOnBlur,
        associatedBroker: loanDetails.associatedBroker,
        lawFirm: loanDetails.lawFirm,
        id: loanDetails?.id
      }}
    >
      <LoanForm
        loanDetails={loanDetails}
        handleFieldChange={handleFieldChange}
        setAttributedBroker={setAttributedBroker}
        setLawFirm={setLawFirm}
        handleLoanLendersChange={handleLoanLendersChange}
        handleLoanBrokersChange={handleLoanBrokersChange}
        saveLoading={saveLoading}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        handleCancel={handleCancel}
        errorsList={[...errorsList, ...validationErrors]}
        validateOnBlur={validateOnBlur}
        lendersAddModal={lendersAddModal}
        brokersAddModal={brokersAddModal}
        stateAndSuburb={stateAndSuburb}
        setPriority={setPriority}
        priority={priority}
        createMode={loanId == null}
      />
      <StatusErrorModal statusErrorModal={statusErrorModal} closeStatusErrorModal={closeStatusErrorModal} />
    </LoanCreateContext.Provider>
  );
};

export default LoansCreate;
