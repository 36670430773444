import env from "./env";
import { templateEngine } from "../libs/utilities";

class ApiUrlHandler {
  constructor() {
    if (!ApiUrlHandler.instance) {
      this.apiUrls = {
        // Dashboard
        DASHBOARD_STATS_URL: "/api/dashboard/stats/<%area%>",
        //"/api/dashboard/stats/500", //
        DASHBOARD_ACTION_URL: "/api/dashboard/action-list",

        // Account
        ACCOUNTS_ME: "/api/accounts/me",
        ACCOUNTS_ME_LAST_VISITED: "/api/accounts/me/last-visited",
        ACCOUNTS_PASSWORD_CHANGE: "/api/accounts/me/password-change",

        // Users
        USERS_URL: "/api/users",
        USERS_DETAIL_URL: "/api/users/<%id%>",

        INDIVIDUAL_USER_DETAIL: "/api/individuals/me",

        // All Users
        USERS_ALL_USER_SEARCH_URL: "/api/users/all",
        USERS_ALL_SYSTEM_USER_SEARCH_URL: "/api/users/all/system-user",
        USERS_ALL_FINANCIER_TYPE_URL: "/api/users/all/financier-type/search-lookup",
        USERS_ALL_SETTLED_LOANS_URL: "/api/users/all/settled-loans/search-lookup",
        USERS_ALL_FIRST_FUNDS_AVAILABLE_URL: "/api/users/all/first-funds-available/search-lookup",
        USERS_ALL_PROFILE_STATUS_URL: "/api/users/all/profile-status/search-lookup",
        GET_ALL_USERS_BDM_LOOKUP: "/api/users/all/omicron-bdm/search-lookup",

        // All Users
        USERS_ALL_USER_SEARCH_URL: "/api/users/all",
        USERS_ALL_SYSTEM_USER_SEARCH_URL: "/api/users/all/system-user",
        USERS_ALL_FINANCIER_TYPE_URL: "/api/users/all/financier-type/search-lookup",
        USERS_ALL_SETTLED_LOANS_URL: "/api/users/all/settled-loans/search-lookup",
        USERS_ALL_FIRST_FUNDS_AVAILABLE_URL: "/api/users/all/first-funds-available/search-lookup",
        USERS_ALL_PROFILE_STATUS_URL: "/api/users/all/profile-status/search-lookup",

        //Companies
        COMPANIES_URL: "/api/companies",
        COMPANIES_LENDER_PROFILE_REMOVE_URL:
          "/api/companies/<%id%>/lender/remove",
        COMPANIES_BROKER_PROFILE_REMOVE_URL:
          "/api/companies/<%id%>/broker/remove",
        COMPANIES_DELETE_URL: "/api/companies/<%id%>/delete",
        COMPANIES_DELETE_LAWFIRM_URL: "/api/companies/<%id%>/delete/law-firm",

        // Individual profiles
        INDIVIDUALS_LIST_URL: "/api/individuals",
        INDIVIDUAL_DETAIL_URL: "/api/individuals/<%id%>",
        INDIVIDUAL_DETAIL_SYSTEM_USER_URL:
          "/api/individuals/<%id%>/system-user",
        COMPANIES_ADD_BROKER_PROFILE_URL: "/api/companies/<%id%>/broker",
        INDIVIDUALS_BROKER_PROFILE_REMOVE_URL:
          "/api/individuals/<%id%>/broker/remove",
        COMPANIES_ADD_LENDER_PROFILE_URL: "/api/companies/<%id%>/lender",
        INDIVIDUALS_LENDER_PROFILE_REMOVE_URL:
          "/api/individuals/<%id%>/lender/remove",
        INDIVIDUALS_DELETE_URL: "/api/individuals/<%id%>/delete",

        INDIVIDUALS_RESEND_INVITATION:
          "/api/individuals/<%id%>/resend-invitation",
        INDIVIDUALS_RESET_PASSWORD: "/api/individuals/<%id%>/reset-password",

        // V2 apis for lender update
        LENDER_UPDATE_INDIVIDUAL_URL: "/api/lenders/<%id%>",
        LENDER_UPDATE_SYSTEM_USER_URL: "/api/lenders/<%id%>/system-user",

        // v2 apis for broker update
        BROKER_UPDATE_INDIVIDUAL_URL: "/api/brokers/<%id%>",
        BROKER_UPDATE_SYSTEM_USER_URL: "/api/brokers/<%id%>/system-user",

        // Master Switch
        MASTER_SWITCH_TURN_OFF_URL:
          "/api/companies/<%id%>/master-switch/turn-off",
        MASTER_SWITCH_TURN_ON_URL:
          "/api/companies/<%id%>/master-switch/turn-on",

        // Profile Status
        LENDER_PROFILE_STATUS_OFF_URL: "/api/lenders/<%id%>/pause-profile",
        LENDER_PROFILE_STATUS_ON_URL: "/api/lenders/<%id%>/activate-profile",
        BROKER_PROFILE_STATUS_OFF_URL: "/api/brokers/<%id%>/pause-profile",
        BROKER_PROFILE_STATUS_ON_URL: "/api/brokers/<%id%>/activate-profile",

        // Products
        LENDER_PRODUCT_STATUS_TOGGLE:
          "/api/lenders/<%id%>/categories/<%productType%>/toggle-status",
        BROKER_PRODUCT_STATUS_TOGGLE:
          "/api/brokers/<%id%>/subscriptions/<%productType%>/toggle-status",

        // File notes
        LENDER_FILE_NOTES_URL: "/api/lenders/<%id%>/file-notes",
        LENDER_FILE_NOTES_SYSTEM_USER_URL:
          "/api/lenders/<%id%>/file-notes/system-user",
        LENDER_FILE_NOTES_DELETE_URL: "/api/lenders/<%id%>/file-notes/delete",
        LENDER_FILE_NOTES_DELETE_SYSTEM_USER_URL:
          "/api/lenders/<%id%>/file-notes/delete/system-user",
        BROKER_FILE_NOTES_URL: "/api/brokers/<%id%>/file-notes",
        BROKER_FILE_NOTES_SYSTEM_USER_URL:
          "/api/brokers/<%id%>/file-notes/system-user",
        BROKER_FILE_NOTES_DELETE_URL: "/api/brokers/<%id%>/file-notes/delete",
        BROKER_FILE_NOTES_DELETE_SYSTEM_USER_URL:
          "/api/brokers/<%id%>/file-notes/delete/system-user",

        //NOTES PAGE
        NOTES_SYSTEM_USER_URL: "/api/notes/system-user",
        NOTES_NON_SYSTEM_USER_URL: "/api/notes",
        MONTHLYSCORES_SYSTEM_USER_URL: "/api/notes/scores/monthly/system-user",
        DAILYSCORES_SYSTEM_USER_URL: "/api/notes/scores/daily/system-user",
        MONTHLYSCORES_INDIVIDUAL_USER_URL: "/api/notes/scores/monthly",
        DAILYSCORES_INDIVIDUAL_USER_URL: "/api/notes/scores/daily",

        NOTE_TYPE_URL: "/api/notes/note-type-dropdown",

        //LOANS
        LOANS_VERIFY_LOANNO: "/api/loans/verify/loan-exist",

        // CREATE BY SYSTEM USER
        LOANS_CREATE: "/api/loans/system-user",
        LOANS_EDIT: "/api/loans/<%id%>/system-user",
        LOAN_DETAIL: "/api/loans/<%id%>/details",

        // LOOKUPS
        COMPANY_OMICRON_BDM_LOOKUP: "/api/companies/omicron-bdm/search-lookup",
        LOANS_SECURITY_TYPE_LOOKUP: "/api/loans/security-type/search-lookup",
        LOANS_STATUS_LOOKUP: "/api/loans/status/search-lookup",
        LOANS_INTEREST_LOOKUP: "/api/loans/interest/search-lookup",
        LOANS_SCENARIO_LOOKUP: "/api/loans/scenario/search-lookup",
        LOANS_LENDERS_LOOKUP: "/api/loans/lenders/search-lookup/system-user",
        LOANS_BROKERS_LOOKUP: "/api/loans/brokers/search-lookup/system-user",
        LOANS_PARTNER_ASSOCIATE_LOOKUP: "/api/loans/bdm/search-lookup/system-user",
        LOANS_PARTNER_ASSOCIATE_LOOKUP_AS_SYSTEM_USER: "/api/loans/bdm/search-lookup",
        LOANS_SUBURB_STATES_LOOKUP: "/api/loans/suburb-states/search-lookup",
        LOANS_PRIORITY_LOOKUP: "/api/loans/priority/search-lookup",
        LOANS_CREDIT_LOOKUP: "/api/loans/credits/search-lookup",
        LOANS_SUBDIVISION_TYPE_LOOKUP: "/api/loans/scenario/subdivision/type/search-lookup",
        //---Check User Involvement
        CHECK_USER_INVOLVEMENT: "/api/loans/<%id%>/check-user-involvement",

        //---UNINVOLVED USER
        GET_LOAN_DETAILS_FOR_UNINVOLVED_USER: "/api/loans/<%id%>/details",
        GET_LOAN_DETAILS_FOR_BROKER_USER: "/api/loans/<%id%>/broker/details",
        GET_LOAN_DETAILS_FOR_LENDER_USER: "/api/loans/<%id%>/lender/details",

        // CREATE BY SYSTEM USER
        LOANS_CREATE: "/api/loans/system-user",
        LOANS_EDIT: "/api/loans/<%id%>/system-user",
        LOAN_DETAIL: "/api/loans/<%id%>/details",

        // LOOKUPS
        LOANS_SECURITY_TYPE_LOOKUP: "/api/loans/security-type/search-lookup",
        LOANS_STATUS_LOOKUP: "/api/loans/status/search-lookup",
        LOANS_SCENARIO_LOOKUP: "/api/loans/scenario/search-lookup",
        LOANS_LENDERS_LOOKUP: "/api/loans/lenders/search-lookup/system-user",
        LOANS_BROKERS_LOOKUP: "/api/loans/brokers/search-lookup/system-user",
        LOANS_LAWFIRM_LOOKUP: "/api/loans/law-firms/search-lookup/system-user",
        LOANS_LAWFIRM_USERS_LOOKUP:
          "/api/loans/law-firms/<%id%>/users/search-lookup/system-user",
        LOANS_SUBURB_STATES_LOOKUP: "/api/loans/suburb-states/search-lookup",
        LOANS_PRIORITY_LOOKUP: "/api/loans/priority/search-lookup",

        // PRIVACY SETTINGS
        GET_LENDER_PRIVACY_SETTINGS: "/api/lenders/<%id%>/privacy-settings",

        // PRIVACY SETTINGS
        GET_INDIVIDUAL_EMAIL_SUBSCRIPTIONS:
          "/api/individuals/<%id%>/email-subscriptions",

        //---GETTING LOAN DETAILS
        GET_LOAN_DETAILS_FOR_SYSTEM_USER:
          "/api/loans/<%id%>/system-user/details",
        GET_BROKER_LOAN_DETAILS_FOR_SYSTEM_USER:
          "/api/loans/<%id%>/broker/details",
        GET_LENDER_LOAN_DETAILS_FOR_SYSTEM_USER:
          "/api/loans/<%id%>/lender/details",
        GET_LOAN_ATTRIBUTED_LENDER_FOR_SYSTEM_USER:
          "/api/loans/<%id%>/details/loan-lenders/system-user",
        GET_LOAN_ATTRIBUTED_LENDER: "/api/loans/<%id%>/details/loan-lenders",
        GET_LOAN_ATTRIBUTED_BROKERS_FOR_SYSTEM_USER:
          "/api/loans/<%id%>/details/brokers/system-user",
        GET_LOAN_ATTRIBUTED_BROKERS: "/api/loans/<%id%>/details/brokers",

        GET_MATCHING_LOANS_FOR_LENDER_URL: "/api/lenders/<%id%>/loans/search",
        GET_MATCHING_LOANS_FOR_LENDER_URL_AS_SYSTEM_USER:
          "/api/lenders/<%id%>/loans/search/system-user",
        GET_MATCHING_LOANS_FOR_BROKER_URL: "/api/brokers/<%id%>/loans/search",
        GET_MATCHING_LOANS_FOR_BROKER_URL_AS_SYSTEM_USER:
          "/api/brokers/<%id%>/loans/search/system-user",
        //---PRESETTLED LOANS
        GET_PRESETTLED_LOANS_FOR_LENDER_URL:
          "/api/loans/lender/<%id%>/presettled",
        GET_PRESETTLED_LOANS_FOR_BROKER_URL:
          "/api/loans/broker/<%id%>/presettled",
        GET_PRESETTLED_LOANS_FOR_LAW_FIRM_URL:
          "/api/lawfirms/<%id%>/loans/presettled",

        GET_PRESETTLED_LOANS_FOR_LENDER_AS_ADMIN_URL:
          "/api/loans/lender/<%id%>/presettled/system-user",

        GET_PRESETTLED_LOANS_FOR_BROKER_AS_ADMIN_URL:
          "/api/loans/broker/<%id%>/presettled/system-user",
        GET_PRESETTLED_LOANS_FOR_LAW_FIRM_URL_AS_ADMIN_URL:
          "/api/lawfirms/<%id%>/loans/presettled/system-user",

        DELETE_LOAN_AS_SYSTEM_USER: "/api/loans/<%id%>/delete",

        UPDATE_LOAN_IN_VIEW_AS_SYSTEM_USER: "/api/loans/<%loanid%>/system-user",
        UPDATE_LOAN_IN_VIEW_AS_LENDER: "/api/loans/<%loanid%>/reviews/lender",
        UPDATE_LOAN_IN_VIEW_AS_BROKER: "/api/loans/<%loanid%>/reviews/broker",
        UPDATE_LOAN_IN_VIEW_AS_LAWFIRM: "/api/loans/<%loanid%>/reviews/lawfirm",

        GET_PRESETTLED_LOANS_FOR_LENDER: "/api/loans/<%id%>",

        //---CURRENT LOANS
        GET_CURRENT_LOANS_FOR_LENDER_URL: "/api/loans/lender/<%id%>/current",
        GET_CURRENT_LOANS_FOR_LENDER_URL_AS_SYSTEM_USER:
          "/api/loans/lender/<%id%>/current/system-user",
        GET_CURRENT_LOANS_FOR_BROKER_URL: "/api/loans/broker/<%id%>/current",
        GET_CURRENT_LOANS_FOR_BROKER_URL_AS_SYSTEM_USER:
          "/api/loans/broker/<%id%>/current/system-user",
        GET_CURRENT_LOANS_FOR_LAW_FIRM_URL:
          "/api/lawfirms/<%id%>/loans/current",
        GET_CURRENT_LOANS_FOR_LAW_FIRM_URL_AS_SYSTEM_USER:
          "/api/lawfirms/<%id%>/loans/current/system-user",

        //---DISCHARGED LOANS
        GET_DISCHARGED_LOANS_FOR_LENDER_URL:
          "/api/loans/lender/<%id%>/discharged",
        GET_DISCHARGED_LOANS_FOR_LENDER_URL_AS_SYSTEM_USER:
          "/api/loans/lender/<%id%>/discharged/system-user",
        GET_DISCHARGED_LOANS_FOR_BROKER_URL:
          "/api/loans/broker/<%id%>/discharged",
        GET_DISCHARGED_LOANS_FOR_BROKER_URL_AS_SYSTEM_USER:
          "/api/loans/broker/<%id%>/discharged/system-user",
        GET_DISCHARGED_LOANS_FOR_LAW_FIRM_URL:
          "/api/lawfirms/<%id%>/loans/discharged",
        GET_DISCHARGED_LOANS_FOR_LAW_FIRM_URL_AS_SYSTEM_USER:
          "/api/lawfirms/<%id%>/loans/discharged/system-user",

        // ALL LOANS
        GET_ALL_LOANS_AS_SYSTEM_USER: "/api/loans/all/system-user",
        GET_ALL_LOANS_AS_INDIVIDUAL: "/api/loans/all/individual-users",

        // ALL LOANS LOOKUPS
        GET_ALL_LOANS_EXPIRY_LOOKUPS: "/api/loans/all/expiry/search-lookup",
        GET_ALL_LOANS_BDM_LOOKUP: "/api/loans/all/omicron-bdm/search-lookup",
        GET_ALL_LOANS_PARTNER_LOOKUPS: "/api/loans/all/partner/search-lookup",

        // Finder Utility
        FINDER_SECURITY_TYPE_LOOKUP: "/api/finder/security-type/search-lookup",
        FINDER_SETTLEMENT_DATE_LOOKUP:
          "/api/finder/settlement-date/search-lookup",
        FINDER_SCENARIO_LOOKUP: "/api/finder/scenario/search-lookup",
        FINDER_LENDERS_LOOKUP: "/api/finder/lenders/search",
        FINDER_LENDERS_LOOKUP_AS_SYSTEM_USER:
          "/api/finder/lenders/search/system-user",
        FINDER_BROKERS_LOOKUP: "/api/finder/brokers/search",
        FINDER_SUBURB_STATES_LOOKUP: "/api/finder/suburb-states/search-lookup",
        FINDER_PRIORITY_LOOKUP: "/api/finder/priority/search-lookup",
        FINDER_RADIUS_LOOKUP: "/api/finder/radius/search-lookup",
        FINDER_TERM_LOWER_LOOKUP: "/api/finder/term/lower/search-lookup",
        FINDER_TERM_HIGHER_LOOKUP: "/api/finder/term/higher/search-lookup",

        // Finder Public
        // Finder Utility
        GENERAL_FINDER_SECURITY_TYPE_LOOKUP:
          "/api/general-public/finder/security-type/search-lookup",
        GENERAL_FINDER_SETTLEMENT_DATE_LOOKUP:
          "/api/general-public/finder/settlement-date/search-lookup",
        GENERAL_FINDER_SCENARIO_LOOKUP:
          "/api/general-public/finder/scenario/search-lookup",
        GENERAL_FINDER_LENDERS_LOOKUP:
          "/api/general-public/finder/lenders/search",
        GENERAL_FINDER_BROKERS_LOOKUP:
          "/api/general-public/finder/brokers/search",
        GENERAL_FINDER_SUBURB_STATES_LOOKUP:
          "/api/general-public/finder/suburb-states/search-lookup",
        GENERAL_FINDER_PRIORITY_LOOKUP:
          "/api/general-public/finder/priority/search-lookup",
        GENERAL_FINDER_RADIUS_LOOKUP:
          "/api/general-public/finder/radius/search-lookup",
        GENERAL_FINDER_TERM_LOWER_LOOKUP:
          "/api/general-public/finder/term/lower/search-lookup",
        GENERAL_FINDER_TERM_HIGHER_LOOKUP:
          "/api/general-public/finder/term/higher/search-lookup",

        //SYSTEM USER ACTIONS
        UPDATE_LENDER_FILE_NOTES_AS_SYSTEM_ADMIN_URL:
          "/api/notes/lender/<%id%>/system-user",
        UPDATE_BROKER_FILE_NOTES_AS_SYSTEM_ADMIN_URL:
          "/api/notes/broker/<%id%>/system-user",
        UPDATE_LAWFIRM_FILE_NOTES_AS_SYSTEM_ADMIN_URL:
          "/api/notes/lawfirm/<%id%>/system-user",
        DELETE_LENDER_FILE_NOTES_AS_SYSTEM_ADMIN_URL:
          "/api/notes/lender/<%id%>/delete/system-user",
        DELETE_BROKER_FILE_NOTES_AS_SYSTEM_ADMIN_URL:
          "/api/notes/broker/<%id%>/delete/system-user",
        DELETE_LAWFIRM_FILE_NOTES_AS_SYSTEM_ADMIN_URL:
          "/api/notes/lawfirm/<%id%>/delete/system-user",

        //INDIVIDUAL USER ACTIONS
        UPDATE_LENDER_FILE_NOTES_AS_INDIVIDUAL_URL: "/api/notes/lender/<%id%>",
        UPDATE_BROKER_FILE_NOTES_AS_INDIVIDUAL_URL: "/api/notes/broker/<%id%>",
        UPDATE_LAWFIRM_FILE_NOTES_AS_INDIVIDUAL_URL:
          "/api/notes/lawfirm/<%id%>",
        DELETE_LENDER_FILE_NOTES_AS_INDIVIDUAL_URL:
          "/api/notes/lender/<%id%>/delete",
        DELETE_BROKER_FILE_NOTES_AS_INDIVIDUAL_URL:
          "/api/notes/broker/<%id%>/delete",
        DELETE_LAWFIRM_FILE_NOTES_AS_INDIVIDUAL_URL:
          "/api/notes/lawfirm/<%id%>/delete",
        INDIVIDUALS_DETAIL_URL: "/api/individuals/<%id%>",

        // Brokers:
        BROKER_DETAIL_URL: "/api/brokers/<%id%>",
        BROKER_SELF_DETAIL_URL: "/api/brokers/<%id%>/me",
        BROKER_DETAIL_SYSTEM_USER_URL: "/api/brokers/<%id%>/system-user",
        BROKER_MATCHES_URL: "/api/brokers/<%id%>/matches",
        BROKER_ADMIN_MATCHES_URL: "/api/brokers/<%id%>/matches/system-user",
        BROKER_PROFESSIONAL_DETAIL_URL:
          "/api/brokers/<%id%>/detail/professional",
        BROKER_PERSONAL_DETAIL_URL: "/api/brokers/<%id%>/detail/personal",
        BROKER_PROFESSIONAL_DETAIL_SYSTEM_USER_URL:
          "/api/brokers/<%id%>/detail/professional-system-user",
        BROKER_PERSONAL_DETAIL_SYSTEM_USER_URL:
          "/api/brokers/<%id%>/detail/personal-system-user",
        BROKER_LOANS_DONE_WITH_YOU: "/api/brokers/<%id%>/loans/done-with-you",

        // Lenders:
        LENDERS_DETAIL_URL: "/api/lenders/<%id%>",
        LENDERS_SELF_DETAIL_URL: "/api/lenders/<%id%>/me",
        LENDERS_DETAIL_SYSTEM_USER_URL: "/api/lenders/<%id%>/system-user",
        LENDERS_PROFESSIONAL_DETAIL_URL:
          "/api/lenders/<%id%>/detail/professional",
        LENDERS_PROFESSIONAL_DETAIL_SYSTEM_USER_URL:
          "/api/lenders/<%id%>/detail/professional-system-user",
        LENDERS_PERSONAL_DETAIL_URL: "/api/lenders/<%id%>/detail/personal",
        LENDERS_PERSONAL_DETAIL_SYSTEM_USER_URL:
          "/api/lenders/<%id%>/detail/personal-system-user",
        LENDERS_FILE_NOTES_URL: "/api/lenders/<%id%>/file-notes",
        LENDERS_FILE_NOTES_DELETE_URL: "/api/lenders/<%id%>/file-notes/delete",
        LENDERS_FILE_NOTES_BY_SYSTEM_USER_URL:
          "/api/lenders/<%id%>/file-notes/system-user",
        LENDERS_FILE_NOTES_BY_SYSTEM_USER_DELETE_URL:
          "/api/lenders/<%id%>/file-notes/delete/system-user",

        LENDERS_FIRST_RANKING_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/first-ranking",
        LENDERS_ADMIN_FIRST_RANKING_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/first-ranking/system-user",
        LENDERS_FIRST_RANKING_VERIFY_URL:
          "/api/lenders/<%id%>/categories/first-ranking/verify",
        LENDERS_ADMIN_FIRST_RANKING_VERIFY_URL:
          "/api/lenders/<%id%>/categories/first-ranking/verify/system-user",

        LENDERS_SECOND_RANKING_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/second-ranking",
        LENDERS_ADMIN_SECOND_RANKING_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/second-ranking/system-user",
        LENDERS_SECOND_RANKING_VERIFY_URL:
          "/api/lenders/<%id%>/categories/second-ranking/verify",
        LENDERS_ADMIN_SECOND_RANKING_VERIFY_URL:
          "/api/lenders/<%id%>/categories/second-ranking/verify/system-user",

        LENDERS_CONSTRUCTION_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/construction",
        LENDERS_ADMIN_CONSTRUCTION_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/construction/system-user",
        LENDERS_CONSTRUCTION_VERIFY_URL:
          "/api/lenders/<%id%>/categories/construction/verify",
        LENDERS_ADMIN_CONSTRUCTION_VERIFY_URL:
          "/api/lenders/<%id%>/categories/construction/verify/system-user",

        LENDERS_MEZZANINE_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/mezzanine",
        LENDERS_ADMIN_MEZZANINE_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/mezzanine/system-user",
        LENDERS_MEZZANINE_VERIFY_URL:
          "/api/lenders/<%id%>/categories/mezzanine/verify",
        LENDERS_ADMIN_MEZZANINE_VERIFY_URL:
          "/api/lenders/<%id%>/categories/mezzanine/verify/system-user",

        LENDERS_LANDBANK_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/land-bank",
        LENDERS_ADMIN_LANDBANK_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/land-bank/system-user",
        LENDERS_LANDBANK_VERIFY_URL:
          "/api/lenders/<%id%>/categories/land-bank/verify",
        LENDERS_ADMIN_LANDBANK_VERIFY_URL:
          "/api/lenders/<%id%>/categories/land-bank/verify/system-user",

        LENDERS_RURAL_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/rural",
        LENDERS_ADMIN_RURAL_DETAIL_PROFILE_URL:
          "/api/lenders/<%id%>/categories/rural/system-user",
        LENDERS_RURAL_VERIFY_URL: "/api/lenders/<%id%>/categories/rural/verify",
        LENDERS_ADMIN_RURAL_VERIFY_URL:
          "/api/lenders/<%id%>/categories/rural/verify/system-user",

        LENDERS_MATCHES_URL: "/api/lenders/<%id%>/matches",
        LENDERS_ADMIN_MATCHES_URL: "/api/lenders/<%id%>/matches/system-user",

        LENDERS_ADMIN_CLEAR_DETAILS_URL:
          "/api/lenders/<%id%>/loan-criteria/clear/system-user",
        LENDERS_CLEAR_DETAILS_URL: "/api/lenders/<%id%>/loan-criteria/clear",
        // Saving of lender's profile drafts before they are verified for the first time

        LENDERS_FIRST_RANKING_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/first-ranking/draft",
        LENDERS_ADMIN_FIRST_RANKING_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/first-ranking/draft/system-user",

        LENDERS_SECOND_RANKING_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/second-ranking/draft",
        LENDERS_ADMIN_SECOND_RANKING_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/second-ranking/draft/system-user",

        LENDERS_CONSTRUCTION_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/construction/draft",
        LENDERS_ADMIN_CONSTRUCTION_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/construction/draft/system-user",

        LENDERS_MEZZANINE_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/mezzanine/draft",
        LENDERS_ADMIN_MEZZANINE_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/mezzanine/draft/system-user",

        LENDERS_LANDBANK_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/land-bank/draft",
        LENDERS_ADMIN_LANDBANK_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/land-bank/draft/system-user",

        LENDERS_RURAL_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/rural/draft",
        LENDERS_ADMIN_RURAL_VERIFY_DRAFT_URL:
          "/api/lenders/<%id%>/categories/rural/draft/system-user",

        LENDERS_LOANS_DONE_WITH_YOU: "/api/lenders/<%id%>/loans/done-with-you",

        //

        // Permissions
        SYSTEM_PERMISSIONS_URL: "/api/roles/system-permissions",
        SYSTEM_PERMISSIONS_URL_ADMIN: "/api/roles/system-permissions/admin",

        // Lookup Url
        POSITIONS_LOOKUP_URL: "/api/users/position/search-lookup",
        STATES_LOOKUP_URL: "/api​/users​/states​/search-lookup",

        // Search Box Lookup
        SEARCH_BOX_FOR_SYSTEM_USER_URL:
          "/api/search/system-user",
        SEARCH_BOX_FOR_LENDER_USER_URL: "/api/search/lender",
        SEARCH_BOX_FOR_BROKER_USER_URL: "/api/search/broker",
        SEARCH_BOX_FOR_DUAL_USER_URL: "/api/search/dual",
        SEARCH_BOX_FOR_LAW_FIRM_URL: "/api/search/law-firm",

        // Lenders Lookup
        LENDERS_FIRST_RANKING_SECURITY_TYPE_LOOKUP_URL:
          "/api/lenders/categories/first-ranking/security-type/search-lookup",
        LENDERS_SECOND_RANKING_SECURITY_TYPE_LOOKUP_URL:
          "/api/lenders/categories/second-ranking/security-type/search-lookup",
        LENDERS_CONSTRUCTION_SECURITY_TYPE_LOOKUP_URL:
          "/api/lenders/categories/construction/security-type/search-lookup",
        LENDERS_MEZZANINE_SECURITY_TYPE_LOOKUP_URL:
          "/api/lenders/categories/mezzanine/security-type/search-lookup",
        LENDERS_LAND_BANK_ZONING_LOOKUP_URL:
          "/api/lenders/categories/land-bank/zoning/search-lookup",
        LENDERS_LAND_BANK_AGRICULTURE_TYPE_LOOKUP_URL:
          "/api/lenders/categories/rural/agriculture-type/search-lookup",
        LENDERS_STATES_SUBURBS_LOOKUP_URL:
          "/api/lenders/states-suburbs/search-lookup",
        LENDERS_STATES_REGIONS_LOOKUP_URL:
          "/api/lenders/states-regions/search-lookup",
        LENDERS_DISTANCES_LOOKUP_URL: "/api/lenders/distances/search-lookup",
        LENDERS_SEARCH_LOOKUP_URL: "/api/lenders/search-lookup",
        LENDERS_SEARCH_LOOKUP_SYSTEM_USER_URL:
          "/api/lenders/search-lookup/system-user",

        //Brokers Lookup
        BROKERS_SEARCH_LOOKUP_URL: "/api/brokers/search-lookup",
        BROKERS_SEARCH_LOOKUP_SYSTEM_USER_URL:
          "/api/brokers/search-lookup/system-user",

        // Matches
        LENDERS_MATCHES_STATUS_LOOKUP_URL: "/api/matches/status/search-lookup/",
        LENDERS_ADMIN_MATCHES_STATUS_LOOKUP_URL:
          "/api/matches/status/search-lookup/system-user",
        UPDATE_MATCHES_STATUS_URL: "/api/matches/status/omicron",
        UPDATE_MATCHES_STATUS_LENDER_URL: "/api/matches/status/lender",
        UPDATE_MATCHES_STATUS_BROKER_URL: "/api/matches/status/broker",

        // Law Firm
        LAW_FIRM_DETAIL_URL: "/api/lawfirms/<%id%>",
        LAW_FIRM_SELF_DETAIL_URL: "/api/lawfirms/<%id%>/me",
        LAW_FIRM_DETAIL_SYSTEM_USER_URL: "/api/lawfirms/<%id%>/system-user",
        LAW_FIRM_PAUSE_PROFILE_URL: "/api/lawfirms/<%id%>/pause-profile",
        LAW_FIRM_ACTIVATE_PROFILE_URL: "/api/lawfirms/<%id%>/activate-profile",
        LAW_FIRM_PROFESSIONAL_DETAIL_URL:
          "/api/lawfirms/<%id%>/detail/professional",
        LAW_FIRM_PROFESSIONAL_DETAIL_SYSTEM_USER_URL:
          "/api/lawfirms/<%id%>/detail/professional/system-user",
        LAW_FIRM_PERSONAL_DETAIL_URL: "/api/lawfirms/<%id%>/detail/personal",
        LAW_FIRM_PERSONAL_DETAIL_SYSTEM_USER_URL:
          "/api/lawfirms/<%id%>/detail/personal/system-user",
        LAW_FIRM_FILE_NOTES_URL: "/api/lawfirms/<%id%>/file-notes",
        LAW_FIRM_FILE_NOTES_DELETE_URL:
          "/api/lawfirms/<%id%>/file-notes/delete",
        LAW_FIRM_FILE_NOTES_SYSTEM_USER_URL:
          "/api/lawfirms/<%id%>/file-notes/system-user",
        LAW_FIRM_FILE_NOTES_DELETE_SYSTEM_USER_URL:
          "/api/lawfirms/<%id%>/file-notes/delete/system-user",

        // Marketing Email Lookups
        MARKETING_LOOKUP_COMPANIES_URL:
          "/api/marketing-email/company/search-lookup",
        MARKETING_LOOKUP_USERS_URL: "/api/marketing-email/user/search-lookup",
        MARKETING_LOOKUP_STATE_URL: "/api/marketing-email/state/search-lookup",
        MARKETING_LOOKUP_USER_TYPE_URL:
          "/api/marketing-email/user-type/search-lookup",
        MARKETING_LOOKUP_VISIBLE_USER_URL:
          "/api/marketing-email/visible-user/search-lookup",
        MARKETING_LOOKUP_CATEGORY_SUBSCRIBE_URL:
          "/api/marketing-email/category-subscribe/search-lookup",
        MARKETING_LOOKUP_DAYS_SUBSCRIBE_URL:
          "/api/marketing-email/days-subscribe/search-lookup",
        MARKETING_LOOKUP_SENDERS_URL: "/api/marketing-email/sender/search-lookup",

        // Marketing Email Send endpoints
        MARKETING_SEND_COMAPANY_URL: "/api/marketing-email/send/company",
        MARKETING_SEND_USER_URL: "/api/marketing-email/send/user",
        MARKETING_SEND_TEST_URL: "/api/marketing-email/send/test-email",
        // Public Brokers/Loans
        PUBLIC_BROKER_DETAILS_URL: "/api/general-public/broker/<%id%>",
        PUBLIC_MATCHING_LOANS_FOR_BROKER_URL: "/api/general-public/broker/<%id%>/loans/search",
        PUBLIC_BROKER_DETAILS_CURRENT_LOANS_URL: "/api/general-public/loans/broker/<%id%>/current",
        PUBLIC_BROKER_DETAILS_DISCHARGED_LOANS_URL: "/api/general-public/loans/broker/<%id%>/discharged",
        PUBLIC_LOANS_DETAILS_URL: "/api/general-public/loans/<%id%>/details",
        PUBLIC_LOANS_DETAILS_ATTRIBUTED_BROKERS_URL: "/api/general-public/loans/<%id%>/details/brokers",
        PUBLIC_LOANS_CREDIT_LOOKUP: "/api/general-public/loans/credits/search-lookup",
        PUBLIC_LOANS_SUBDIVISION_TYPE_LOOKUP: "/api/general-public/loans/credits/search-lookup",

        // Loans History
        LOANS_LOGS_URL: "/api/loans/<%id%>/logs",
        LOANS_LOGS_AS_SYSTEM_USER_URL: "/api/loans/<%id%>/logs/system-user",
        LOANS_LOGS_SNAPSHOT_URL: "/api/loans/<%loanId%>/logs/<%logId%>",
        LOANS_LOGS_SNAPSHOT_AS_SYSTEM_USER_URL: "/api/loans/<%loanId%>/logs/<%logId%>/system-user",
      };
      ApiUrlHandler.instance = this;
    }

    return ApiUrlHandler.instance;
  }

  /**
   * @typedef ConstructEndpointParams
   * @property {string} endpoint
   * @property {Object=} options
   * @property {boolean=} isMocked
   * 
   * @param {ConstructEndpointParams} param 
   * @returns {string}
   * 
   * 
   * Options contain any amount of properties to be extracted and replaced in endpoint string using the 
   * template of <%property%>
   */
  constructEndpoint({ endpoint, options = {}, isMocked }) {
    // If endpoint is not in the apiUrls, it's likely a raw URL
    if (!(endpoint in this.apiUrls)) {
      return isMocked
        ? endpoint
        : env.baseurl + templateEngine(endpoint, options);
    }

    return isMocked
      ? this.apiUrls[endpoint]
      : env.baseurl + templateEngine(this.apiUrls[endpoint], options);
  }
}

const instance = new ApiUrlHandler();
Object.freeze(instance);

export const apiUrls = instance.apiUrls;
export default instance;
